<template>
  <div :key="modifyKey">
    <div class="div_title">
      <context :context=cate />
      <policies/>
    </div>
    <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true" >
      <div class="div_title"><span style="color: #ff7f50;">상품정보</span></div>
      <div v-if="items.length === 0" class="main container_no_login no-result" >
        수정할 상품이 없습니다.
      </div>
      <div v-else id="orders">
        <div v-for="(item, index) in items" :key="index">
          <component :is="item" ref='key' class="card main" :idx='index+1' :route='$route.params' @copy='copy' @remove='remove' :grouped='this.groupedOrders[index]'/>
        </div>
      </div>
      <br>
      <div style="text-align:center;">
        <!-- <input @click="add_item()" style="max-width:100px" class="button_mod" type="button" value="&#43; 상품추가" /> -->
        <Button @click="add_item()" raised label="&#43; 상품추가" />
      </div>
      <div class="div_title"><span style="color: #ff7f50;">추가옵션</span><label style="font-weight: normal">(배송비 결제시 함께 청구됩니다)</label></div>
      <extaraoption ref="extra_option" class="card main" :notice="this.notice" />
      
      <div class="div_title"><span style="color: #ff7f50;">기타요청사항</span> </div>
      <div class="card main">
        <input ref="etc" class="normalInput" type="text" :value="this.extraNotice" placeholder="상품옵션/사이즈 등"/> 
      </div>
      <div class="div_title">
        <span style="color: #ff7f50;">받는사람</span>
        <!-- <input type="button" class='button_mod' style='max-width:100px; padding:5px;' @click='recentInfo()' value="최근배송정보"> -->
        <Button @click='recentInfo()' raised size="small" label="최근배송정보" />
      </div>
      <div class="card main">
        <receiver ref="receiver" :isHelper="isHelper" :orderType="orderType" @update:orderType="orderType = $event" :country="country" @update:country="country = $event" :mail="mail" @update:mail="mail = $event" :phone="phone" @update:phone="phone = $event" :clearance="clearance" @update:clearance="clearance = $event" :orderName="orderName" @update:orderName="orderName = $event" :addrZip="addrZip" @update:addrZip="addrZip = $event" :addrRoad="addrRoad" @update:addrRoad="addrRoad = $event" :addrDetail="addrDetail" @update:addrDetail="addrDetail = $event" />
      </div>
      <div id="agree_section">
        <div class="div_title">
          <span style="color: #ff7f50;">주의사항</span>
          <policies />
        </div>
        <div class="card main">
          <p class="notice">1. GEEK9는 구매자님께서 요청하신 판매자/사이트에 대한 결제와 배송에 대해서만 관여하기 때문에 상품의 진품 여부나 판매자의 사기 여부는 GEEK9도 알 수 없습니다.</p>
          <p class="notice">2. 사기 판매자/사이트나 그외 예상할 수 없는 문제에 대해서 GEEK9는 어떠한 책임도 지지 않습니다.</p>
          <p class="notice">3. 신중한 구매/배송 대행 여부 결정을 부탁드립니다.</p>
          <p class="notice">4. 구매/배송 진행 상황은 위에 입력하신 이메일로 전달되고, 홈페이지에서도 확인 가능합니다.</p>
          <p class="notice">5. 환불/취소를 원하시면 문의 게시판에 환불/취소 의사와 계좌번호를 남겨주시면 됩니다.</p>
          <div class="agree-wrapper">
            <input id="modify-agree-check" ref="chk_agree" class="normal-checkbox" type="checkbox"/> <label for="modify-agree-check" class="link">위의 내용을 확인했고, 동의합니다.</label>
          </div>
        </div>
      </div>
      <div style="text-align:center;">
        <Button ref="modBtn" @click="modify()" label="수정완료" />
      </div>
    </div>
    <login v-else @login="login" /> 
    <Modal v-model='isShow' :close='closeModal' :setModal='setModal' style="z-index: 99999999999;">
      <div class="modal">
        <span style="color:black; font-weight:bold">최근배송정보</span>
        <div id="sub_menu" style="margin-top:20px;margin-bottom:20px;">
          <div v-for="(order, index) in orders" :key="index" >
            <orderinfoc class="content" @closeModal='closeModal' @setModal='setModal' :idx='index+1' :name='order.ORDER_NAME' :clearance='order.CLEARANCE' :addrZip='order.ADDR_ZIP' :addrRoad='order.ADDR_ROAD' :addrDetail='order.ADDR_DETAIL' :mail='order.MAIL' :phone='order.PHONE' :key="orderinfocKey"/>
          </div>
        </div>
        <span>
          <Button @click='closeModal()' raised size="small" label="닫기" />
        </span>
      </div>
    </Modal>
    <!-- <registModal v-if="isRegist" @closeRegistModal="closeRegistModal" @login="login" :loginId="loginId"/> -->
  </div>
</template>

<script>
import receiver from './components/order/receiver.vue'
import item from './components/order/item.vue'
import { defineAsyncComponent, ref } from 'vue'
import Extaraoption from './components/order/extaraoption.vue'
// import { useMeta } from 'vue-meta'
import orderinfoc from './components/content/orderinfo_content.vue';
import moment from 'moment'
import router from './router'
import context from '@/components/common/Context.vue'
import policies from '@/components/common/policies.vue'
import login from '@/components/common/Login.vue'

// import { inject } from 'vue'

export default {
  emits: ["setCookies", "login"],
  mounted() {
    this.emitter.on('modifyReload', this.reload)
    this.items = []
    let uid, orderType, country, notice
    let ordersJson = JSON.parse(JSON.stringify(this.$route.params))
    this.groupedOrders = Object.keys(ordersJson).map( (key) => {
      let order = JSON.parse(ordersJson[key])
      uid = order.UID
      orderType = order.ORDER_TYPE
      country = order.COUNTRY
      notice = order.NOTICE

      this.deliveryKrCompany = order.DELIVERY_KR_COMPANY
      this.deliveryPrice2 = order.DELIVERY_PRICE2
      this.invoiceNumber = order.INVOICE_NUMBER
      this.orderDate = order.ORDER_DATE
      this.sellPrice = order.SELL_PRICE
      this.status = order.STATUS

      this.add_item()
      return order
    })
    if(this.groupedOrders.length === 0) {
      this.$notify('수정할 상품이 없습니다.')
      router.push(({name:"home"}))
    } else {
      this.modifyKey++
      this.api.setPage("modify")
      console.log(this.api.getCookies('page'))
    }
    if(uid !== undefined) {
      this.api.axiosGql('SELECT_ORDER_INFO_BY_UID', {type:'SELECT_ORDER_INFO_BY_UID', ID: "", UID:uid})
      .then( (res) => {
        let result = res.data
        this.orderName = result.ORDER_NAME
        this.clearance = result.CLEARANCE
        this.addrZip = result.ADDR_ZIP
        this.addrRoad = result.ADDR_ROAD
        this.addrDetail = result.ADDR_DETAIL
        this.phone = result.PHONE
        this.mail = result.MAIL 
        this.orderType = orderType
        this.country = country
      }).catch( (err) => {
        console.log(err)
      })
      this.originUid = uid
      this.notice = notice
      this.extraNotice = ''

      let split = String(this.notice).split(',')
      split.map( (item) => {
        if( item.indexOf('원산지증명 만원') !== -1) return
        if( item.indexOf('사진촬영비용 5000원') !== -1) return
        if( item.indexOf('포장보완비용 5000원') !== -1) return
        if( item.indexOf('와인 8000원 x 수량') !== -1) return
        this.extraNotice += item.trim()
      })
    }
    window.scrollTo(0, 0)
  },
  unmounted() {
    this.emitter.off('modifyReload', this.reload)
  },
  data() { 
    return { 
      modifyKey:-1,
      orderName: '',
      clearance: '',
      addrZip: '',
      addrRoad: '',
      addrDetail: '',
      phone: '',
      mail: '',
      orderType: '',
      country: '',
      isHelper: false,
      orderinfocKey:-1
    }; 
  },
  components: {
    item, receiver, Extaraoption, orderinfoc, context, policies, login,
    // registModal,
    item_tag: defineAsyncComponent(() => import("./components/order/item.vue"))
  },
  setup() {
    // const setMeta = inject('setMeta')
    // setMeta({
    //   title: 'GEEK9 - 한방에 직구하기',
    //   keywords: '직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,3PL,풀필먼트',
    //   description: '해외직구/구매대행/배송대행/결제대행/해외운송/이사/3PL',
    //   'og:title': 'GEEK9 - 한방에 직구하기',
    //   'og:description': '해외직구/구매대행/배송대행/결제대행/해외운송/이사/3PL',
    //   'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
    //   'og:url': 'https://geek9.kr',
    //   'canonical': 'https://geek9.kr'
    // })
    let items = ref([])
    let orders = ref([])
    let groupedOrders = ref([])
    let originUid, notice, extraNotice, deliveryKrCompany, deliveryPrice2, invoiceNumber, orderDate, sellPrice, status
    // useMeta( {
    //   title: 'GEEK9 - 한방에 직구하기',
    //   link: [{rel: "canonical", href: 'https://geek9.kr'}],
    //   meta: [
    //     { vmid: 'charset_mod', charset: 'utf-8' },
    //     { vmid: 'http-equiv_mod', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_mod', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_mod', name: 'keywords', content:'직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,3PL,풀필먼트' },
    //     { vmid: 'viewport_mod', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_mod', name: 'description', content: '해외직구/구매대행/배송대행/결제대행/해외운송/이사/3PL' },
    //     { vmid: 'theme-color_mod', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_mod', property: 'og:site_name', content: 'GEEK9'},
        // { vmid: 'og:description_mod', property: 'og:description', content: ''},
        // { vmid: 'og:title_mod', property: 'og:title', content: ''},
    //     { vmid: 'og:image_mod', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_mod', property: 'og:url', content: 'https://geek9.kr/'}
    //   ]
    // })
    const add_item = () => {
      items.value.push('item_tag')
    }
    const isShow = ref(false)
    async function showModal () {
      isShow.value = true;
      const id = this.api.getCookies('id')
      
      await this.api.axiosGql('SELECT_ORDER_INFO_LIST', {type:'SELECT_ORDER_INFO_LIST', id: ""+id})
      .then( (res) => {
        orders.value = res.data
      })
    }
    function closeModal () {
      isShow.value = false
    }
    const cate = ref([
      { label: '주문'},
      { label: '수정', route: '/modify' }
    ])
    return {
      add_item, items, closeModal, showModal, orders, isShow, groupedOrders, originUid, notice, extraNotice, deliveryKrCompany, deliveryPrice2, invoiceNumber, orderDate, sellPrice, status, cate
      // showRegistModal, closeRegistModal, isRegist, loginId
    }
  },
  methods: {
    login() {
      this.$emit('login')
    },
    setModal(orderInfo) {
      this.$refs.receiver.$refs.name.value = orderInfo.name
      this.$refs.receiver.$refs.clearance.value = orderInfo.clearance
      this.$refs.receiver.$refs.phone.value = orderInfo.phone
      this.$refs.receiver.$refs.mail.value = orderInfo.mail
      this.$refs.receiver.$refs.addr_detail.value = orderInfo.addrDetail
      this.$refs.receiver.$refs.addr_road.value = orderInfo.addrRoad
      this.$refs.receiver.$refs.addr_zip.value = orderInfo.addrZip
    },
    disableBtn(flag) {
      this.$refs.modBtn.disabled = flag
      this.emitter.emit('dialog', {flag:flag, title:'수정중입니다.'})
      // flag === false ? this.$refs.modBtn.style.backgroundColor = '#ffa07a' : this.$refs.modBtn.style.backgroundColor = '#ccc'
    },
    async modify() {
      this.disableBtn(true)
      if(this.$refs.chk_agree.checked) {
        let order_list = document.querySelectorAll("#orderItem .dynamic-table");
        if(order_list.length == 0) {
          this.$notify('대행 상품 정보가 없습니다')
          this.disableBtn(false)
          return
        }
        if(confirm('수정을 하시게 되면 기존의 책정된 구매대행비가 있는 경우 다시 책정됩니다. 환율에 따라 금액이 변동될 수 있습니다.') === false) {
          this.disableBtn(false)
          return
        }
        let orderArray = new Array()

        const typeTag = this.$refs.receiver.$refs.type
        const countryTag = this.$refs.receiver.$refs.country
        const nameTag = this.$refs.receiver.$refs.name
        const clearanceTag = this.$refs.receiver.$refs.clearance
        const phoneTag = this.$refs.receiver.$refs.phone
        const mailTag = this.$refs.receiver.$refs.mail
        const addrZipTag = this.$refs.receiver.$refs.addr_zip
        const addrRoadTag = this.$refs.receiver.$refs.addr_road
        const addrDetailTag = this.$refs.receiver.$refs.addr_detail

        const type = typeTag.value
        const country = countryTag.value
        const name = nameTag.value
        const clearance = clearanceTag.value
        const phone = phoneTag.value
        const mail = mailTag.value
        const addrZip = addrZipTag.value
        const addrRoad = addrRoadTag.value
        const addrDetail = addrDetailTag.value

        const opt_tag = this.$refs.extra_option.$refs

        let isReceiverValid = true
        if(type === '') {
          this.emitter.emit('confirm', {message: '대행유형을 선택해주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
          typeTag.focus()
          isReceiverValid = false
        } else if(country === '') {
          this.emitter.emit('confirm', {message: '국가를 선택해주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
          countryTag.focus()
          isReceiverValid = false
        } else if(name === '') {
          this.emitter.emit('confirm', {message: '받는 사람 이름을 입력해주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
          nameTag.focus()
          isReceiverValid = false
        } else if(clearance === '') {
          this.emitter.emit('confirm', {message: '통관부호를 입력해주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
          clearanceTag.focus()
          isReceiverValid = false
        } else if(clearance.length !== 13 || (clearance.indexOf('p') === -1 && clearance.indexOf('P') === -1)) {
          this.emitter.emit('confirm', {message: '통관부호를 정확하게 입력해 주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
          clearanceTag.focus()
          isReceiverValid = false
        } else if(phone === '') {
          this.emitter.emit('confirm', {message: '받는 사람 연락처를 입력해주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
          phoneTag.focus()
          isReceiverValid = false
        } else if(mail === '') {
          this.emitter.emit('confirm', {message: '받는 사람 메일을 입력해주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
          mailTag.focus()
          isReceiverValid = false
        } else if(addrZip === '' || addrRoad === '') {
          this.emitter.emit('confirm', {message: '받는 사람 주소를 입력해주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
          addrZipTag.focus()
          isReceiverValid = false
        } else if(country !== '프랑스' && opt_tag.chk_wine.checked === true) {
          this.emitter.emit('confirm', {message: '와인대행은 프랑스만 가능합니다', header:'수정', icon:'pi pi-exclamation-triangle'})
          countryTag.focus()
          isReceiverValid = false
        }

        if(isReceiverValid === false) {
          this.disableBtn(false)
          return
        }
        if(country === '대만' && type === '배송대행') {
          this.disableBtn(false)
          this.emitter.emit('confirm', {message: '대만은 구매대행만 가능합니다.', header:'대행신청', icon:'pi pi-exclamation-triangle'})
          return
        }
        // const receiver_list = this.$refs.receiver.$el.querySelectorAll('input, select')
        // let clearance
        // for(var i=0; i<receiver_list.length; i++) {
        //   if('' === receiver_list[i].value && i !== 7 && i !== 8) {
        //     this.$notify("받는 사람 정보 중 누락된 정보가 있습니다");
        //     receiver_list[i].focus()
        //     this.disableBtn(false)
        //     return;
        //   } else if(i === 3) {
        //     clearance = receiver_list[3].value
        //     if(clearance.length !== 13 || (clearance.indexOf('p') === -1 && clearance.indexOf('P') === -1)) {
        //       this.$notify("통관부호를 정확하게 입력해 주세요");
        //       receiver_list[i].focus()
        //       this.disableBtn(false)
        //       return
        //     }
        //   }
        // }
        // const type = receiver_list[0].value
        // const country = receiver_list[1].value
        let etc = ''
        let picturePrice = '0'
        let packagePrice = '0'
        // if(opt_tag.chk_fta.checked === true) etc += '원산지증명 만원, '
        if(opt_tag.chk_picture.checked === true) {
          etc += '사진촬영비용 5000원, '
          picturePrice = '5000'
        }
        if(opt_tag.chk_packing.checked === true) {
          etc += '포장보완비용 5000원, '
          packagePrice = '5000'
        }
        if(opt_tag.chk_wine.checked === true) etc += '와인 8000원 x 수량, '
        etc += this.$refs.etc.value

        for(var k=0; k<order_list.length; k++) {
          let input_list = order_list[k].querySelectorAll("#orderItem .normalInput");
          let orderJson = new Object()
          for(var j=0; j<input_list.length; j++) {
            const value = input_list[j].value
            if(j === 3 && value > 99999999 ) {
              alert( (k+1) + "번째 상품의 해외 가격이 너무 높습니다.");
              input_list[j].focus()
              window.scrollTo(0, 0)
              this.disableBtn(false)
              return
            } else if( '' === value && j !== 2 &&  j !== 6 &&  j !== 7 &&  j !== 8) {
              this.$notify( (k+1) + "번째 상품 정보 중 누락된 정보가 있습니다");
              input_list[j].focus()
              this.disableBtn(false)
              return
            } 
            
            else if(j === 0) {
              if(value.indexOf('carousell') === -1 || value.indexOf('shopee') === -1) orderJson.URL = value 
              else orderJson.URL = this.api.shortenUrl(value)
            } else if(j === 1) orderJson.NAME = value
            else if(j === 2) orderJson.IMG_URL = value
            else if(j === 3) orderJson.ORIGIN_PRICE = value
            else if(j === 4) {
              let intAmount = value
              intAmount = intAmount *= 1
              if(intAmount === undefined || intAmount <= 0) {
                alert('구매수량을 입력해주시기 바랍니다.')
                input_list[j].focus()
                window.scrollTo(0, 0)
                this.disableBtn(false)
                return
              }
              orderJson.AMOUNT = intAmount
            }
            else if(j === 5) orderJson.ORDER_NUMBER = value
            else if(j === 6) orderJson.TRACKING_NUMBER = value
            else if(j === 7) orderJson.SELL_PRICE = parseInt(value)
            else orderJson.BRAND = value
          }
          orderJson.NOTICE = etc
          orderJson.COUNTRY = country
          orderJson.ORDER_TYPE = type 

          if(type==='배송대행' && orderJson.TRACKING_NUMBER.length > 3) {
            this.status = '입고대기'
            orderJson.STATUS = 4
          }
          orderArray.push(orderJson)
        }
        const loginId = this.api.getCookies('id')

        if(loginId) {
          let orderInfoJson = {type:'INSERT_USER', mail: mail, addrZip: addrZip, addrRoad: addrRoad, addrDetail: addrDetail, name: name, clearance: clearance, phone: phone, id:loginId}
          await this.api.axiosGql('INSERT_USER', orderInfoJson)
          .then( (res) => {
            if(res.data === '') console.log('Already Existed User')
            else if(res.data.NAME === name ) console.log('INSERT_USER SUCCESS')
            else {
              this.$notify('다시 시도해주세요.')  
              this.disableBtn(false)
              return
            }
            orderInfoJson.type = 'INSERT_ORDER_INFO'
            let orderDtlUid = ''
            this.api.axiosGql('INSERT_ORDER_INFO', orderInfoJson)
            .then( async (res) => {
              const uid = res.data.UID
              if(uid !== null ) {
                orderArray.map(json => { 
                  json.UID = uid
                  orderDtlUid = uid
                  json.ID = loginId
                  if(json.BRAND === undefined || json.BRAND === '') {
                    let split = String(json.URL).split('.')
                    json.BRAND = split[1]
                    if(json.BRAND.indexOf('com') !== -1 || json.BRAND.indexOf('net') !== -1) {
                      json.BRAND = split[0].replace(/http:\/\//g, '').replace(/https:\/\//g, '')
                    }
                  } else if(json.BRAND.length > 15) json.BRAND = json.BRAND.substring(0, 15)
                  json.REG_DATE = moment(new Date()).format('YYYYMMDD')
                  json.ORDER_DATE = moment(new Date()).format('YYYYMMDD')
                  json.ETC = 'modify'
                })

                let paramArray = new Array()
                for(var i = 0; i < orderArray.length; i++) {
                  const orderJson = orderArray[i]
                  paramArray.push(JSON.stringify(orderJson))
                }
                const formData = new FormData()
                formData.append("list", paramArray)
                formData.append('slack', '수정')
                formData.append('type', 'INSERT_ORDER_LIST')

                await this.api.axiosGql('INSERT_ORDER_LIST', formData)
                .then( async (res) => {
                  if(res.data !== undefined ) {
                    await this.api.axiosGql('UPDATE_ORDER_USE_YN', {type:'UPDATE_ORDER_USE_YN', uid:this.originUid, id: "" + this.api.getCookies('id'), useYn: 0})
                    .then( async () => {
                      await this.api.axiosGql('UPDATE_ORDER_INFO_USE_YN', {type:'UPDATE_ORDER_INFO_USE_YN', UID:this.originUid, ID: "" + this.api.getCookies('id'), USE_YN: 0})
                      .then( async () => {
                        await this.api.axiosGql('INSERT_ORDER_HISTORY', {type:'INSERT_ORDER_HISTORY', id: "" + this.api.getCookies('id'), uidBefore: this.originUid, uidAfter: uid, content: ''})
                        .then( async () => {
                          // await this.api.axiosGql('UPDATE_ORDER_BY_UID', {type:'UPDATE_ORDER_BY_UID', uid: uid, deliveryKrCompany: this.deliveryKrCompany, deliveryPrice2: parseInt(this.deliveryPrice2), invoiceNumber: this.invoiceNumber, orderDate: this.orderDate, sellPrice: parseInt(this.sellPrice), status: this.api.getOrderStatus(this.status)})
                          await this.api.axiosGql('UPDATE_ORDER_BY_UID', {type:'UPDATE_ORDER_BY_UID', uid: uid, deliveryKrCompany: this.deliveryKrCompany, invoiceNumber: this.invoiceNumber, status: this.api.getOrderStatus(this.status)})
                          this.emitter.emit('dialog', {flag:false})
                          this.emitter.emit('confirm', {message: '수정되었습니다.', header:'로그아웃', icon:'pi pi-info-circle'})
                          router.push(({name:"list"}))
                          // TDOD START : UPSERT_ORDER_PRICE_DETAIL 
                          if(!(orderDtlUid === undefined || orderDtlUid === '')) {
                            this.api.axiosGql('DELETE_ORDER_PRICE_DTL', {type:'DELETE_ORDER_PRICE_DTL', uid:this.originUid})
                            this.api.axiosGql('UPSERT_ORDER_PRICE_DTL', {type:'UPSERT_ORDER_PRICE_DTL', uid:orderDtlUid, picturePrice:picturePrice, packagePrice:packagePrice})
                          }
                          // TDOD END : UPSERT_ORDER_PRICE_DETAIL
                        })
                      })
                    })
                    .catch( (err) => {
                      this.api.axiosGql('DELETE_ORDER_INFO_BY_UID', {type:'DELETE_ORDER_INFO_BY_UID', UID: uid, ID: "" + this.api.getCookies('id')})
                      this.api.axiosGql('DELETE_ORDER_BY_UID', {type:'DELETE_ORDER_BY_UID', uid:uid, id: "" + this.api.getCookies('id')})
                      this.emitter.emit('confirm', {message: '다시 시도해주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
            
                      this.disableBtn(false)
                      console.log(err)
                    })
                  } else {
                    this.emitter.emit('confirm', {message: '다시 시도해주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
                    this.disableBtn(false)
                    return
                  }
                })
                .catch( (err) => {
                  this.emitter.emit('confirm', {message: '다시 시도해주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
                  this.disableBtn(false)
                  console.log(err)
                })
              } else {
                this.emitter.emit('confirm', {message: '다시 시도해주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
                this.disableBtn(false)
                return
              }
            })
            .catch( (err) => {
              this.emitter.emit('confirm', {message: '다시 시도해주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
              this.disableBtn(false)
              console.log(err)
            })
          })
          .catch( () => {
            this.emitter.emit('confirm', {message: '다시 시도해주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
            this.disableBtn(false)
          }) 
        } else {
          this.emitter.emit('confirm', {message: '로그인 후 다시 시도해주세요', header:'수정', icon:'pi pi-exclamation-triangle'})
          this.disableBtn(false)
          this.$emit('login')
        }
      } else {
        this.disableBtn(false)
        const el = document.getElementById('agree_section')
        el.setAttribute('class', 'blink')
        this.emitter.emit('confirm', {message: '주의사항에 동의해주셔야 등록이 가능합니다', header:'수정', icon:'pi pi-exclamation-triangle'})
      }
    },
    // async request() {
    //   window.Kakao.API.request({
    //     url: '/v2/user/me',
    //     success: async (response) => {
    //       let flag = false
    //       await this.api.axiosGql('SELECT_COMMON_USER', {type: 'SELECT_COMMON_USER', id:response.id })
    //       .then( async (res) => {
    //         const result = res.data
    //         if(result === 'null' || result === undefined || result === null ) flag = false
    //         else flag = true
    //       })
    //       .catch((err) => {
    //         console.log(err)
    //       })
    //       if(flag) {
    //         this.emitter.emit("setCookies", {login:true, id:response.id, nickname:response.properties.nickname, img:response.properties.thumbnail_image, mail:response.kakao_account.email})
    //       } else this.showRegistModal(response.id)
    //     },
    //     fail: (error) => {
    //       this.$notify("다시 시도해주세요.")
    //       console.log(error)
    //     }
    //   })
    // },
    // async login() {
    //   window.Kakao.Auth.login({
    //     success: () => {
    //       this.tmp = '로그인'
    //       this.request();
    //     },
    //     fail: () => {
    //       this.$notify("다시 시도해주세요.")
    //     },
    //   })
    // },
    // logout() {
    //   window.Kakao.Auth.logout(() => {
    //     this.emitter.emit("setCookies", {login:false, id:'', nickname:'',img:'', mail:'', comnum:'', comname:''})
    //     this.tmp = 'not'
    //     this.$notify('로그아웃 되었습니다.');
    //   });
    // },
    getCookies(key) {
      return this.api.getCookies(key)
    },
    async copy(idx) {
      await this.add_item()
      idx--
      let order_list = document.querySelectorAll("#orderItem .dynamic-table")
      let input_list = order_list[idx].querySelectorAll("#orderItem .normalInput")

      const url = input_list[0].value
      const name = input_list[1].value
      const imgUrl = input_list[2].value
      const price = input_list[3].value
      const amount = input_list[4].value
      const orderNumber = input_list[5].value
      const trackingNumber = input_list[6].value
      let wonPrice = ''
      if(input_list[7] !== undefined) wonPrice = input_list[7].value 
      input_list = order_list[order_list.length-1].querySelectorAll("#orderItem .normalInput")

      input_list[0].value = url
      input_list[1].value = name
      input_list[2].value = imgUrl
      input_list[3].value = price
      input_list[4].value = amount
      input_list[5].value = orderNumber
      input_list[6].value = trackingNumber
      if(input_list[7] !== undefined) input_list[7].value = wonPrice
    },
    reload(isLogin) {
      if(isLogin === 'dashboard') this.modifyKey++
    },
    recentInfo() {
      if(this.api.getCookies('id')) {
        this.showModal()
      } else {
        this.$notify('로그인 후 다시 시도해주세요.')
        this.$emit('login')
      }
    },
    remove(idx) {
      idx--;
      let order_list = document.querySelectorAll("#orderItem .dynamic-table")
      let order_value_list = []
      for(var i=0; i<order_list.length; i++) {
        if(i !== idx) {
          let input_list = order_list[i].querySelectorAll("#orderItem .normalInput")

          const url = input_list[0].value
          const name = input_list[1].value
          const imgUrl = input_list[2].value
          const price = input_list[3].value
          const amount = input_list[4].value
          const orderNumber = input_list[5].value
          const trackingNumber = input_list[6].value
          const sellPrice = input_list[7].value

          order_value_list[order_value_list.length] = {
            "url":url,
            "name":name,
            "imgUrl":imgUrl, 
            "price":price,
            "amount":amount,
            "orderNumber":orderNumber,
            "trackingNumber":trackingNumber,
            "sellPrice":sellPrice}
        }
      }
      this.items.splice(idx, 1)
      order_list = document.querySelectorAll("#orderItem .dynamic-table")
      
      for(var j=0; j<order_value_list.length; j++) {
        let input_list = order_list[j].querySelectorAll("#orderItem .normalInput")
        let json = order_value_list[j]
        input_list[0].value = json.url
        input_list[1].value = json.name
        input_list[2].value = json.imgUrl
        input_list[3].value = json.price
        input_list[4].value = json.amount
        input_list[5].value = json.orderNumber
        input_list[6].value = json.trackingNumber
        input_list[7].value = json.sellPrice
      }
    }
  }
}
</script>

<style>
.button_mod {
  background-color: lightsalmon;  
  color: white;
  padding: 10px;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  min-width: 100px;
  cursor: pointer;
  margin-bottom: 15px;
}
p.notice {
  color: red;
  margin-left: 10px;
}
</style>